export const SPECS = {
  MinimumOrderStorefront: 'specs.stores.MinimumOrderStorefront',
  CartTooltipWithoutNumber: 'specs.stores.CartTooltipWithoutNumber',
  PaypalUpdateShippingAndBilling: 'specs.stores.PaypalUpdateShippingAndBilling',
  showShippingMethodCashierExpressButton: 'specs.stores.ShowShippingMethodInApplePayModal',
  EcomPlatformCartAndCheckout: 'specs.stores.EcomPlatformCartAndCheckout',
  AddPickupPointsToCart: 'specs.stores.AddPickupPointsToCart',
  SecureCheckoutVelo: 'specs.stores.ShowHideSecureCheckoutVelo',
  SendUpdateBuyerNoteBiInCart: 'specs.stores.SendUpdateBuyerNoteBiInCart',
  PickupPointsBmPhase1: 'specs.stores.PickupPointsBmPhase1',
  DisplayAdditionalFees: 'specs.stores.DisplayAdditionalFees',
  VeloValidationOnCheckout: 'specs.stores.veloValidationOnCheckout',
  MultiCurrencyPOC: 'specs.stores.MultiCurrencyPOC',
  MoveCartBIEventsTo130: 'specs.stores.MoveCartBIEventsTo130',
  DisplayMixedCart: 'specs.stores.DisplayMixedCart',
} as const;
